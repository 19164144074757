<template>
  <p class="control">
    <input
      :id="$attrs.id || `${formId}.${fieldId}.${fieldKey}`"
      v-model="__value"
      :form="formId"
      v-bind="$attrs"
      :value="originalValue"
      :class="inputClass"
      class="is-checkradio"
      type="radio"
      @click.stop
    >
    <label :for="$attrs.id || `${formId}.${fieldId}.${fieldKey}`">
      <slot name="default" />
    </label>
    <slot name="after" />
  </p>
</template>

<script lang="js">
export default {
  inheritAttrs: false,
  props: {
    fieldId: {
      type: String,
      required: false,
      default: 'field',
    },
    fieldKey: {
      type: String,
      required: false,
      default: 'generic',
    },
    formId: {
      type: String,
      required: false,
      default: 'form',
    },

    inputClass: {
      type: [Array, String, Object],
      required: false,
      default: null,
    },
    // eslint-disable-next-line vue/require-default-prop
    value: [String, Number, Boolean, Function, Object, Array, Symbol],
    // eslint-disable-next-line vue/require-default-prop
    originalValue: [String, Number, Boolean, Function, Object, Array, Symbol],
  },
  data() {
    return {
      dataValue: this.value,
    };
  },
  computed: {
    // eslint-disable-next-line no-underscore-dangle
    __value: {
      get() {
        return this.dataValue;
      },
      set(value) {
        this.dataValue = value;
        this.$emit('input', value);
      },
    },
  },
  watch: {
    value(value) {
      this.dataValue = value;
    },
  },
};
</script>

<style lang="sass" scoped>
@import "@/styles/_bulma-customization.sass"
@import "@/styles/_bulma-checkradio.sass"
</style>
