











































import Vue from 'vue';

/*
interface SelectOption {
  value: any
  disabled: boolean,
  text: string
}
*/

export default Vue.extend({
  inheritAttrs: false,
  props: {
    fieldId: {
      type: String,
      required: false,
      default: 'field',
    },
    fieldKey: {
      type: String,
      required: false,
      default: 'generic',
    },
    formId: {
      type: String,
      required: false,
      default: 'form',
    },

    hasError: Boolean,
    isExpanded: Boolean,
    isFullwidth: Boolean,
    size: {
      type: String,
      required: false,
      default: 'normal',
    },

    defaultOption: {
      type: Object,
      required: false,
      default: null,
    },
    selectOptions: { // {text/tk: string, value: any, disabled: bool|undefined}
      type: Array,
      required: true,
    },

    inputClass: {
      type: Array,
      required: false,
      default: (): never[] => [],
    },

    // This is required because otherwise the input wouldn't be reactive to v-model
    // Because this should neither have a default value nor type:
    // eslint-disable-next-line vue/require-prop-types,vue/require-default-prop
    value: {},
  },
  computed: {
    // eslint-disable-next-line no-underscore-dangle
    __listeners(): Record<string, (event: Event) => void> {
      return {
        ...this.$listeners,
        input: this.onInput,
      };
    },
  },
  methods: {
    onInput(event: Event) {
      this.$emit('input', (event.target as HTMLInputElement).value);
      (this.$refs.select as HTMLInputElement).setCustomValidity('');
    },
    $v(msg?: string): boolean {
      const i = this.$refs.select as HTMLSelectElement;

      i.setCustomValidity(msg || '');
      i.reportValidity();

      return i.checkValidity();
    },
  },
});
