

























import Vue from 'vue';

function leftPad(input: string, length: number, pad: string): string {
  let str = input;

  while (str.length < length) {
    str = `${pad}${str}`;
  }

  return str;
}

function formatDateString(date: Date): string {
  return `${
    leftPad(date.getFullYear().toString(), 4, '0')
  }-${
    leftPad((date.getMonth() + 1).toString(), 2, '0')
  }-${
    leftPad(date.getDate().toString(), 2, '0')
  }`;
}

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/datetime-local
 *
 * @returns "yyyy-MM-ddThh:mm"
 */
function formatDateTimeString(date: Date): string {
  return `${
    leftPad(date.getFullYear().toString(), 4, '0')
  }-${
    leftPad((date.getMonth() + 1).toString(), 2, '0')
  }-${
    leftPad(date.getDate().toString(), 2, '0')
  }T${
    leftPad((date.getHours()).toString(), 2, '0')
  }:${
    leftPad(date.getMinutes().toString(), 2, '0')
  }`;
}

export default Vue.extend({
  inheritAttrs: false,
  props: {
    dateMax: {
      type: Date,
      required: true,
    },
    dateMin: {
      type: Date,
      required: true,
    },
    dateStep: {
      type: Number,
      required: false,
      default: 60,
    },

    fieldId: {
      type: String,
      required: false,
      default: 'field',
    },
    fieldKey: {
      type: String,
      required: false,
      default: 'generic',
    },
    formId: {
      type: String,
      required: false,
      default: 'form',
    },

    hasError: Boolean,

    size: {
      type: String,
      required: false,
      default: 'normal',
    },
    // This is required because otherwise the input wouldn't be reactive to v-model
    value: {
      type: Date,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isDateTime: true,
    };
  },
  computed: {
    listeners(): Record<string, (event: Event) => void> {
      return {
        ...this.$listeners,
        input: this.onInput,
        // This component's model will return an instanceof Date
      };
    },
    inputType(): string {
      return this.isDateTime ? 'datetime-local' : 'date';
    },
    max(): string {
      return this.isDateTime
        ? formatDateTimeString(new Date(this.dateMax))
        : formatDateString(new Date(this.dateMax));
    },
    min(): string {
      return this.isDateTime
        ? formatDateTimeString(new Date(this.dateMin))
        : formatDateString(new Date(this.dateMin));
    },
    val(): string|null {
      if (!this.value) return null;

      if (this.isDateTime) return formatDateTimeString(new Date(this.value));
      return formatDateString(new Date(this.value));
    },
  },
  mounted(): void {
    // * https://caniuse.com/input-datetime
    const isChrome = 'chrome' in window;
    if (!isChrome) {
      this.isDateTime = false;
      this.$emit('browser-supports-input-datetime-local', false);
    }
    // ! This is currently only used for deadline input, which doesnt necessarily *require* a time
    // ! In other scenarios, this might be a dealbreaker.
  },
  methods: {
    onInput(event: Event): void {
      console.debug(new Date((event.target as HTMLInputElement).value));
      this.$emit('input', new Date((event.target as HTMLInputElement).value));
      (this.$refs.input as HTMLInputElement).setCustomValidity('');
    },
    $v(msg?: string): boolean {
      const i = this.$refs.input as HTMLInputElement;

      i.setCustomValidity(msg || '');
      i.reportValidity();

      return i.checkValidity();
    },
  },
});
