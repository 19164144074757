var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"control",class:{'is-loading': _vm.isLoading, 'is-expanded': _vm.isExpanded}},[_c('textarea',_vm._g(_vm._b({ref:"textarea",staticClass:"textarea",class:_vm.inputClass.concat( [("is-" + _vm.size)],
      [{
        'is-danger': _vm.hasError,
        'numeric-tabular-nums': _vm.hasNumbers,
        'has-fixed-size': _vm.hasFixedSize,
        'is-static': _vm.isStatic,
        'is-fullwidth': _vm.isFullwidth
      }]
    ),attrs:{"id":_vm.$attrs.id || (_vm.formId + "." + _vm.fieldId + "." + _vm.fieldKey),"placeholder":_vm.$attrs.placeholder ? _vm.$attrs.placeholder : _vm.$t(("placeholder." + _vm.fieldKey)),"form":_vm.formId,"readonly":_vm.isReadonly},domProps:{"value":_vm.value}},'textarea',_vm.$attrs,false),_vm.__listeners)),_vm._v(" "),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }