<template>
  <label
    :for="`${formId}.${fieldId}.${fieldKey}`"
    class="label"
    v-text="labelText ? labelText : $t(fieldKey)"
  />
</template>

<script lang="js">
export default {
  props: {
    fieldId: {
      type: String,
      required: false,
      default: 'field',
    },
    fieldKey: {
      type: String,
      required: false,
      default: 'generic',
    },
    formId: {
      type: String,
      required: false,
      default: 'form',
    },
    labelText: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>

<style lang="sass" scoped>
label.label.labels-share-line
  @apply mb-2 inline
</style>

<i18n lang="yaml" locale="en">
account:
  appearance:
    icon: Icon
  name: Your name
auth:
  identifier: Your email address
bankAccount:
  accountNumber: Account number
  CLABE: CLABE
  routingNumber:
    bsb: BSB
    routing_number: Routing number
    bank_code: Bank code
    transit_number: Transit number
    institution_number: Institution number
    clearing_code: Clearing code
    branch_code: BranchCode
    sort_code: Sort code
bankAccountHolder:
  name: Account holder name
  type: Account holder type
business:
  slots: Open commission slots
commission:
  file:
    item_id: Required payment item
    name: File name
    size: size
    type: type
  item:
    price: Item price
    unit: unit
    qty: quantity
    purpose: Name
    description: Extended description
    total: Gross price
    fee: fee amount
    fee_percent: percent
    earnings: earnings amount
  message: Send a message
  reject:
    reason: Rejection reason
  summary: Commission request
  budget: Budget
  deadline: Deadline
country: Country
currency: Currency
default-storage-zone: Default Cloud Storage Zone
email: Email address
iban: IBAN
new-password: New passphrase
password: Passphrase
pricing: Pricing Model
profile:
  banner: Maker page banner
  country: My country of residence
  summary: Maker page summary
  dob: Date of birth
  firstName: First name
  icon: Maker page icon
  lastName: Last name
  name: Maker Name
  terms: Terms of Service
  type: I am creating this maker page for…
  vanity: Vanity URL
  share:
    url: Maker page URL
    shortUrl: Short URL
    permalink: Permalink
  page:
    title: Title
    slug: Slug
    weight: Weight
    public: Public?
    type: Page Type
    content:
      markdown: Markdown Content
      link: External Link
  pricing: Pricing model
public_key_credential:
  display_name: Device name
settings:
  country: Your country
shipping:
  tracking_number: Tracking number
  carrier: Carrier
totp: Two-Factor Code
totp_recovery_secret: Recovery Code
verify-password: Repeat new passphrase
</i18n>
<i18n lang="yaml" locale="de">
account:
  appearance:
    icon: Icon
  name: Dein Name
auth:
  identifier: Deine Email-Addresse
bankAccount:
  accountNumber: Kontonummer
  CLABE: CLABE
  routingNumber:
    bsb: BSB
    routing_number: Routing-Nummer
    bank_code: Bankleitzahl
    transit_number: Transit-Nummer
    institution_number: Institutions-Nummer
    clearing_code: Verrechnungs-Code
    branch_code: BranchCode
    sort_code: Sort Code
bankAccountHolder:
  name: Name des Kontoinhabers
  type: Typ des Kontoinhabers
business:
  slots: Offene Aufträge
commission:
  file:
    item_id: Erforderlicher Artikel
    name: Dateiname
    size: Größe
    type: Typ
  item:
    price: Preis
    unit: Einheit
    qty: Menge
    purpose: Name
    description: Ausführlichere Beschreibung
    total: Brutto-Preis
    fee: Gebührenbetrag
    fee_percent: Prozent
    earnings: Verdiensthöhe
  message: Nachricht senden
  reject:
    reason: Grund der Ablehnung
  budget: Budget
  deadline: Frist
  summary: Auftragsanfrage
country: Land
currency: Währung
default-storage-zone: Standard Cloudspeicher-Zone
email: Email-Addresse
iban: IBAN
new-password: Neue Passphrase
password: Passphrase
pricing: Preismodell
profile:
  banner: Makerprofil Banner
  country: Mein Heimatland
  summary: Makerprofil Text
  dob: Geburtsdatum
  firstName: Vorname
  icon: Makerprofil Icon
  lastName: Nachname
  name: Maker Name
  terms: Nutzungsbedingungen
  type: Ich erstelle dieses Makerprofil für…
  vanity: Wunsch-URL
  share:
    url: Makerprofil URL
    shortUrl: Kurz URL
    permalink: Permalink
  page:
    title: Titel
    slug: URL
    weight: Gewichtung
    public: Öffentlich?
    type: Typ
    content:
      markdown: Markdown Inhalt
      link: Externer Link
  pricing: Preismodell
public_key_credential:
  display_name: Gerätename
settings:
  country: Dein Land
shipping:
  tracking_number: Sendungsnummer
  carrier: Carrier
totp: Zwei-Faktor-Code
totp_recovery_secret: Wiederherstellungs-Code
verify-password: Neue Passphrase wiederholen
</i18n>
