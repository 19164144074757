<template>
  <div
    :class="{'is-horizontal': isHorizontal}"
    class="field"
  >
    <FormLabel
      v-if="hasImplicitLabel"
      :class="[`is-${isSize}`, {'field-label': isHorizontal}]"
      :field-key="fieldKey"
      :field-id="fieldId"
      :form-id="formId"
    />
    <slot
      v-else
      name="label"
    />
    <div
      :class="{
        'has-addons': hasAddons,
        'is-grouped': isGrouped,
        'field-body': isHorizontal,
        'field': !isHorizontal
      }"
    >
      <slot name="default" />
    </div>
    <slot name="validation">
      <FormValidationError
        :field-key="fieldKey"
        :validation-error="fieldValidation"
      />
    </slot>
    <slot name="help" />
  </div>
</template>

<script lang="js">
export default {
  props: {
    // Used to distinguish duplicates of the same field key
    fieldId: {
      type: String,
      required: false,
      default: 'field',
    },
    // Provides translation key and label/input id
    fieldKey: {
      type: String,
      required: false,
      default: 'generic',
    },
    // Field validatione error
    fieldValidation: {
      type: String,
      required: false,
      default: null,
    },
    formId: {
      type: String,
      required: false,
      default: 'form',
    },
    hasAddons: Boolean,
    hasImplicitLabel: Boolean,
    isGrouped: Boolean,
    isHorizontal: Boolean,
    isSize: {
      type: String,
      required: false,
      default: 'normal',
    },
  },
};
</script>

<style lang="sass">
@import "@/styles/_bulma-customization.sass"
@import "~bulma/sass/form/shared.sass"
// .label, .help, .field, .control
@import "~bulma/sass/form/tools.sass"
</style>
