

























































import Vue from 'vue';

export default Vue.extend({
  inheritAttrs: false,
  props: {
    fieldId: {
      type: String,
      required: false,
      default: 'field',
    },
    fieldKey: {
      type: String,
      required: false,
      default: 'generic',
    },
    formId: {
      type: String,
      required: false,
      default: 'form',
    },

    hasError: Boolean,
    hasIcon: Boolean,
    hasName: Boolean,

    labelClass: {
      type: Array,
      required: false,
      default: (): never[] => [],
    },
    nameClass: {
      type: Array,
      required: false,
      default: (): never[] => [],
    },

    // This is required because otherwise the input wouldn't be reactive to v-model
    // Because this should neither have a default value nor type:
    // eslint-disable-next-line vue/require-prop-types,vue/require-default-prop
    value: {} as () => File[],
  },
  computed: {
    // eslint-disable-next-line no-underscore-dangle
    __listeners(): Record<string, (event: Event) => void> {
      return {
        ...this.$listeners,
        input: (event: Event): void => void this.$emit('input', (event.target as HTMLInputElement).files),
      };
    },
    file(): File|null {
      return this.files[0] || null;
    },
    fileName(): string|null {
      return this.file ? this.file.name : null;
    },
    files(): File[] {
      return this.value || [];
    },
  },
  watch: {
    value(to) {
      if (to === null) this.$attrs.value = '';
    },
  },
});
