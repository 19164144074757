
































import Vue from 'vue';

export default Vue.extend({
  inheritAttrs: false,
  props: {
    fieldId: {
      type: String,
      required: false,
      default: 'field',
    },
    fieldKey: {
      type: String,
      required: false,
      default: 'generic',
    },
    formId: {
      type: String,
      required: false,
      default: 'form',
    },

    hasError: Boolean,
    hasNumbers: Boolean,

    inputClass: {
      type: Array,
      required: false,
      default: (): never[] => [],
    },

    isExpanded: Boolean,
    isFullwidth: Boolean,
    isLoading: Boolean,
    isStatic: Boolean,
    isReadonly: Boolean,
    hasFixedSize: Boolean,

    size: {
      type: String,
      required: false,
      default: 'normal',
    },

    // This is required because otherwise the input wouldn't be reactive to v-model
    // Because this should neither have a default value nor type:
    // eslint-disable-next-line vue/require-prop-types,vue/require-default-prop
    value: {},
  },
  computed: {
    // eslint-disable-next-line no-underscore-dangle
    __listeners(): Record<string, (event: Event) => void> {
      return {
        ...this.$listeners,
        input: this.onInput,
      };
    },
  },
  methods: {
    onInput(event: Event) {
      this.$emit('input', (event.target as HTMLTextAreaElement).value || null);
      (this.$refs.textarea as HTMLTextAreaElement).setCustomValidity('');
    },
    $v(msg?: string): boolean {
      const i = this.$refs.textarea as HTMLTextAreaElement;

      i.setCustomValidity(msg || '');
      i.reportValidity();

      return i.checkValidity();
    },
  },
});
