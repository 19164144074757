<template>
  <TransitionValidation>
    <p
      v-if="validationError"
      class="help is-danger"
      v-text="$t(`v.${fieldKey}.${validationError}`)"
    />
  </TransitionValidation>
</template>

<script lang="js">
export default {
  props: {
    fieldKey: {
      type: String,
      required: true,
    },
    validationError: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>
