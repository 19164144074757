

























import Vue from 'vue';

// Returns yyyy-MM-dd
function formatDateString(date: Date): string {
  let year: string = date.getFullYear().toString();
  let month: number|string = date.getMonth() + 1;
  let day: number|string = date.getDate();

  // Left-pad with "0" to ensure format is respected.
  // Without left-padding the year, the input will break while
  // manually typing a date, as it will temporarily be `1-06-03`
  // instead of `0001-06-03`, while typing `1999-06-03`

  while (year.length < 4) {
    year = `0${year}`;
  }

  month = month < 10 ? `0${month}` : month;
  day = day < 10 ? `0${day}` : day;

  return `${year}-${month}-${day}`;
}

export default Vue.extend({
  inheritAttrs: false,
  props: {
    dateMax: {
      type: Date,
      required: false,
      default(): Date {
        return new Date(); // Now
      },
    },
    dateMin: {
      type: Date,
      required: false,
      default(): Date {
        return new Date('1900-01-01'); // 1. Jan. 1900
      },
    },

    fieldId: {
      type: String,
      required: false,
      default: 'field',
    },
    fieldKey: {
      type: String,
      required: false,
      default: 'generic',
    },
    formId: {
      type: String,
      required: false,
      default: 'form',
    },

    hasError: Boolean,

    size: {
      type: String,
      required: false,
      default: 'normal',
    },
    // This is required because otherwise the input wouldn't be reactive to v-model
    value: {
      type: Date,
      required: false,
      default: null,
    },
  },
  computed: {
    listeners(): Record<string, (event: Event) => void> {
      return {
        ...this.$listeners,
        input: (event: Event): void => void this.$emit('input', new Date((event.target as HTMLInputElement).value)),
        // This component's model will return an instanceof Date
      };
    },
    max(): string {
      return formatDateString(new Date(this.dateMax));
    },
    min(): string {
      return formatDateString(new Date(this.dateMin));
    },
    val(): string|null {
      return this.value ? formatDateString(new Date(this.value)) : null;
    },
  },
});
