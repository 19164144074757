

























import Vue from 'vue';
import { AxiosError } from 'axios';

function isAxiosError(err: any): err is AxiosError {
  return !!err && !!err.config && !!err.request;
}

function isApiError(err: any): boolean {
  if (!err.isAxiosError) return false;

  const { response } = err;

  if (!response) return false;

  return !!response.headers['auf-request-id'];
}

export default Vue.extend({
  props: {
    error: {
      type: [Error, Object],
      required: false,
      default: null,
    },
    isDismissable: Boolean,
  },
  data() {
    return {
      isDismissed: false,
    };
  },
  computed: {
    show(): boolean {
      return !!this.error && this.isDismissed === false;
    },
    errorMessage(): string | undefined {
      const e = this.error;

      if (!e) return undefined;
      if (e.isAxiosError) {
        const { response } = e;
        if (response) {
          if (response.status === 401 && response.data?.hint) {
            return response.data.hint;
          }
          if (response.headers['auf-request-id'] && response.data?.error) {
            return response.data.error.message;
          }
        }
      }
      if (e.message) return e.message;
      return undefined;
    },
    errorCode(): string | undefined {
      const e = this.error;
      if (!e) return undefined;
      if (isAxiosError(e)) {
        const { response } = e;
        if (isApiError(e) && response?.data?.error?.code) {
          return `A-${response.data.error.code}`;
        }
        if (response?.data.code) return `E-${response.data.code}`;
        if (response) return `HTTP-${response.status}`;
        return `HTTP-${e.name}`;
      }
      if (e.name) return `JS-${e.name}`;
      return 'U-ERROR';
    },
  },
  watch: {
    error(to, from) {
      if (to !== from) {
        console.debug('[ErrorMessage]', 'Error changed.');
        this.isDismissed = false;
        this.handleError(to);
      }
    },
  },
  methods: {
    dismiss() {
      if (!this.isDismissable) return;
      this.isDismissed = true;
      this.$emit('error-dismissed', this.error);
    },
    handleError(e: Error|null): void {
      console.debug('[Error]', e, isAxiosError(e));
      console.error(e);

      if (isAxiosError(e) && isApiError(e)) {
        if (e.response?.status === 401 && e.response.data.hint) {
          this.$store.dispatch('oauth2/refresh');
        } else if (e.response?.status === 409 && e.response.data.error?.type?.startsWith('conflict.auth.')) {
          this.handleLoginError();
        } else if (e.response?.status === 422 && e.response.data.error?.fields) {
          this.handleValidationError();
        }
      }
    },
    handleValidationError(): void {
      this.$emit('error-validation', this.error.response.data.error.fields);
    },
    handleLoginError() {
      this.$emit('error-auth', this.error.response.data.error);
    },
  },
});
